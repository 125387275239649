import React from 'react'
import {Container, Grid, Link} from '@mui/material'
import { graphql } from 'gatsby'
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Helmet} from "react-helmet";

function createData(time, name, speaker, link, abstractLink) {
    return {time, name, speaker, link, abstractLink};
}

const titleName = "BrainNN22 - Schedule"

const rows = [
    createData('8:30-8:40', "Introduction and Opening Remarks", "Dr. Carl Yang",
        "https://cs.emory.edu/~jyang71/", ""),
    createData('8:40-9:05', "Invited Talk 1", "Dr. Lifang He",
        "http://www.cse.lehigh.edu/~lifanghe/",
        "https://drive.google.com/file/d/1Kkk8Ty9q8MkqQd2ICUW8LWgcnPK65NzV/view?usp=sharing"),
    createData('9:05-9:30', "Invited Talk 2", "Dr. Archana Venkataraman",
        "https://engineering.jhu.edu/faculty/archana-venkataraman/",
        "https://drive.google.com/file/d/1xGQkctdWpGKgDNF8xloQJyNjhi3HNNl4/view?usp=sharing"),
    createData('9:30-9:55', "Invited Talk 3", "Dr. Liang Zhan",
        "https://www.engineeringx.pitt.edu/People/Faculty/Profiles/Liang-Zhan/",
        "https://drive.google.com/file/d/1Maymz1ffBv7gzrrEebdBT3oJ8OJE6p2O/view?usp=sharing"),
    createData('9:55-10:20', "Invited Talk 4", "Dr. Ying Guo",
        "https://sph.emory.edu/faculty/profile/index.php?FID=ying-guo-629",
        "https://drive.google.com/file/d/1pAAvt7FZTWtnomqqYtbwsyFXsHHOqWmg/view?usp=sharing"),
    createData('10:20-10:40', "Coffee Break", "", "", ""),
    createData('10:40-10:55', "Paper Spotlight 1", "Zijian Dong", "", "https://drive.google.com/file/d/1FmuGYzaTO2pm02BJiFUc4q7dRl7UjjVu/view?usp=sharing"),
    createData('10:55-11:10', "Paper Spotlight 2", "Ethan Young", "", "https://drive.google.com/file/d/1DmwIwHMXUq6xhdyt19Q33aQdvClcQszU/view?usp=sharing"),
    createData('11:10-11:25', "Paper Spotlight 3", "Xuan Kan", "", "https://drive.google.com/file/d/1OSPBZ_4fHpgxHtHi-1EGVF8E7riXGZ3r/view?usp=sharing"),
    createData('11:25-11:40', "Paper Spotlight 4", "Alexis Li", "", "https://drive.google.com/file/d/17cKlypIOa2NS2yTqL9Z-ZKBkWuqP-GRg/view?usp=sharing"),
    createData('11:40-12:05', "Invited Talk 5", "Dr. Yize Zhao",
        "https://ysph.yale.edu/profile/yize_zhao/",
        "https://drive.google.com/file/d/102OoKfExT6SIJQHq33KeZsaQrQeRNhW7/view?usp=sharing"),
    createData('12:05-12:30', "Invited Talk 6", "Dr. Ehsan Adeli",
        "https://stanford.edu/~eadeli/",
        "https://drive.google.com/file/d/1ci14-Cu2hW0FdI-SdUcDkcwKH875kMvh/view?usp=sharing"),
    createData('12:30-12:40', "Closing Remarks", "Dr. Carl Yang",
        "https://cs.emory.edu/~jyang71/", ""),
];

const Schedule = ({ data }) => {
    return (
        <Grid
            direction='row'
            justifyContent="center"
            alignItems="stretch"
            display="flex"
            container
        >
            <Helmet>
                <title>{titleName}</title>
            </Helmet>
            <Container component="article" dangerouslySetInnerHTML={{
                __html: data.markdownRemark.html
            }} style={{marginTop: "50px"}}/>


            <Grid flexDirection="row"
                  justifyContent={'center'}
                  display='flex'
                  style={{width: "100%", padding: "45px", backgroundColor: '#f6fafd'}}
                  container>
                <Grid item justify="left" alignItems="left"
                      xs={12} sm={4}>
                    <h1 style={{color: '#1e376d', textAlign: 'center'}}>Half-day Schedule</h1>
                </Grid>

                <Grid item justify="center" alignItems="center"
                      xs={12} sm={8} sx={{paddingLeft: "20px", paddingRight: "20px"}}>
                    <TableContainer component={Paper}
                                    sx={{ minWidth: 400, maxWidth: 750}}>
                        <Table aria-label="simple table">
                            <TableHead  adjustForCheckbox={false}>
                                <TableRow>
                                    <TableCell>&emsp;Time (Tokyo)</TableCell>
                                    <TableCell>Event</TableCell>
                                    <TableCell>Speaker</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            &emsp;{row.time}
                                        </TableCell>
                                        <TableCell>
                                            {row.abstractLink === "" ? row.name :
                                                <Link href={row.abstractLink} target="_blank" rel="noreferrer">
                                                    {row.name}
                                                </Link>}
                                        </TableCell>
                                        <TableCell>{row.link === "" ? row.speaker :
                                            <Link href={row.link} target="_blank" rel="noreferrer">
                                                {row.speaker}
                                            </Link>}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Schedule
export const pageQuery = graphql`
    query AdvancedPageQuery {
        markdownRemark(fileAbsolutePath: {regex: "/posts/schedule.md$/"}) {
            html
        }
    }
`
